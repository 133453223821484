// Generated code; DO NOT EDIT

const buildinfo = {
    version: '0.1.0',
    gitcommit: 'c0aede9',
    buildDate: '2025-01-03T12:21:10.237Z',
};

console.log('version: 0.1.0 git: c0aede9 date: 2025-01-03T12:21:10.237Z');

export default buildinfo;
