import { ReactElement, ReactNode, useState } from 'react';
import Keycloak from 'keycloak-js';
import deukaLogo from '../../../assets/deuka.svg';
import deukaCompanionLogo from '../../../assets/deukaCompanionLogo.png';
import clubLogo from '../../../assets/club.0x100.png';
import nordkraftLogo from '../../../assets/nordkraft.0x100.png';
import { routes } from '../../../store/Workflow.store';
import { slide as Menu, State } from 'react-burger-menu';
import iconClose from '../../../assets/icon-close.svg';
import iconBurger from '../../../assets/icon-burger.svg';
import IconButton, { IconSize } from '../IconButton/IconButton';
import { Link, useLocation } from 'react-router-dom';
import { NavigationGroup } from '../../../store/Navigation.store';
import { useSelector } from 'react-redux';
import Footer, { FOOTER_LINKS } from '../Footer/Footer';
import CookieBanner from '../CookieBanner/CookieBanner';
import { ALLOW_COOKIES_KEY } from '../../../localStorage';
import FeatureToggle from '../FeatureToggle/FeatureToggle';
import AddToHomescreenBanner from '../AddToHomescreenBanner/AddToHomescreenBanner';
import type { RootState } from '../../../configureStore';
import styles from './Shell.module.scss';
import './burgermenu.scss';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';
import { CurrentUser } from '../../types';
import { Account } from './components/Account';
import FlutterBridge from '../../../flutter/flutterBridge';
import Config from '../../../shared/config';
import { getData } from '../../../shared/fetchApi';

const { isApp } = Config.getConfig();

export type Props = Readonly<{
    children: ReactNode;
    keycloak: Partial<Keycloak>;
    currentUser: CurrentUser;
}>;

const Shell = (props: Props): ReactElement => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const location = useLocation();
    const menuEntries: NavigationGroup[] = useSelector((rootState: RootState): NavigationGroup[] => rootState.navigation.navigationGroups);
    const [cookieBannerVisible, setCookieBannerVisible] = useState(localStorage.getItem(ALLOW_COOKIES_KEY) === null);
    const isDesktop = useBreakpoint(Breakpoint.DESKTOP);

    const checkUnreadMessages = async () => {
        try {
            const response = await getData<boolean>('nachrichten/unread');
            console.log('Antwort von /nachrichten/unread:', response);
            setHasUnreadMessages(response);
        } catch (error) {
            console.error('Fehler beim Abrufen der ungelesenen Nachrichten:', error);
        }
    };

    const openMenu = (): void => {
        setMenuIsOpen(true);
        checkUnreadMessages();
    };

    const closeMenu = (): void => {
        setMenuIsOpen(false);
    };

    const setMenuOpen = (state: State): void => {
        setMenuIsOpen(state.isOpen);
    };

    const showCookieBanner = (): void => {
        setCookieBannerVisible(true);
    };

    const hideCookieBanner = (): void => {
        setCookieBannerVisible(false);
    };

    return (
        <>
            <FeatureToggle featureName="PROGRESSIVE_WEB_APP">
                <AddToHomescreenBanner />
            </FeatureToggle>
            <Menu
                right
                outerContainerId="root"
                pageWrapId="page-container"
                isOpen={menuIsOpen}
                onStateChange={setMenuOpen}
                customCrossIcon={<img src={iconClose} alt="Navigation schließen" />}
                width={isDesktop ? 300 : '80%'}
            >
                <Account
                    username={props.currentUser.name}
                    keycloak={props.keycloak}
                    setMenuIsOpen={setMenuIsOpen}
                    hasUnreadMessages={hasUnreadMessages}
                    setHasUnreadMessages={setHasUnreadMessages}
                />
                <hr className={styles._divider} />
                {menuEntries.map((group, groupIndex) => (
                    <div key={groupIndex} className={styles._menuItemGroup}>
                        <label className={styles._menuLabel}>{group.groupLabel}</label>
                        {group.entries.map((entry, entryIndex) => {
                            const className =
                                location.pathname === entry.route && entry.route !== routes.underConstruction
                                    ? [styles._menuItem, styles['_menuItem--active']].join(' ')
                                    : styles._menuItem;

                            if (entry.isExternalLink) {
                                return (
                                    <a
                                        data-cy={`navigation${entry.name}`}
                                        key={entryIndex}
                                        onClick={closeMenu}
                                        href={entry.route}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={className}
                                    >
                                        {entry.label}
                                    </a>
                                );
                            }

                            return (
                                <Link
                                    data-cy={`navigation${entry.name}`}
                                    key={entryIndex}
                                    onClick={closeMenu}
                                    to={entry.route}
                                    className={className}
                                >
                                    {entry.label}
                                </Link>
                            );
                        })}
                    </div>
                ))}
                {isApp() ? (
                    <a
                        onClick={closeMenu}
                        href={routes.accountLoeschen}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles._accountLoeschen}
                    >
                        <>Account löschen</>
                    </a>
                ) : null}
                {isDesktop ? null : (
                    <div key={'footer-links'} className={styles._menuItemGroup}>
                        <label className={styles._menuLabel}>Hinweise</label>
                        {[
                            ...FOOTER_LINKS.map((entry, index) => {
                                return (
                                    <a
                                        key={index}
                                        onClick={closeMenu}
                                        href={entry.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles._menuItem}
                                    >
                                        {entry.label}
                                    </a>
                                );
                            }),
                            <a
                                key={'footer_menu_entry_cookie_settings'}
                                target="_blank"
                                onClick={() => {
                                    closeMenu();
                                    showCookieBanner();
                                }}
                                className={styles._menuItem}
                            >
                                Cookie-Einstellungen
                            </a>,
                            isApp() ? (
                                <a
                                    href={routes.lizenzen}
                                    key={'footer_menu_entry_licenses'}
                                    onClick={FlutterBridge.sendLicenses}
                                    className={styles._menuItem}
                                >
                                    Lizenzen
                                </a>
                            ) : null,
                        ]}
                    </div>
                )}
            </Menu>
            <div id="page-container" className={styles._container}>
                <div className={styles._mainBackground} />
                <div className={styles._headerBackground} />
                <header className={styles._header}>
                    <div className={styles._headerContent}>
                        <div className={styles._deukaContainer}>
                            <Link to={'/'}>
                                <img src={deukaLogo} className={styles._deukalogo} alt="Deuka logo" />
                            </Link>
                        </div>
                        <img src={deukaCompanionLogo} className={styles._deukaCompanionlogo} alt="Companion Logo" />
                        <img src={clubLogo} className={styles._clublogo} alt="Club logo" />
                        <img src={nordkraftLogo} className={styles._nordkraftlogo} alt="Nordkraft logo" />
                    </div>
                    <IconButton burger={true} icon={iconBurger} iconSize={IconSize.LARGE} alt="Navigation öffnen" onClick={openMenu} />
                </header>
                <div className={styles._stickyFix}></div>
                <main className={styles._main}>{props.children}</main>
                {[routes.npRechner, routes.fragenUndAntworten].indexOf(location.pathname) === -1 ? (
                    <div className={styles._footer}>
                        <Footer onShowCookieBanner={showCookieBanner} />
                    </div>
                ) : null}
                <CookieBanner onHideCookieBanner={hideCookieBanner} bannerVisible={cookieBannerVisible} />
            </div>
        </>
    );
};

export default Shell;
