import Keycloak from 'keycloak-js';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectHasOpenBestellung } from '../../../../store/Workflow.store';
import { clearBestellung } from '../../../../store/Bestellung.store';
import { ALLOW_COOKIES_KEY } from '../../../../localStorage';
import { showConfirmationDialog } from '../../ConfirmationDialog/confirmationDialog';
import styles from '../Shell.module.scss';
import accountIcon from '../../../../assets/icon-account.svg';
import notificationIcon from '../../../../assets/notifications.png';
import FlutterBridge from '../../../../flutter/flutterBridge';
import Config from '../../../../shared/config';
import { routes } from '../../../constants';
import { markAllMessagesAsRead } from '../../../../shared/fetchApi';

type AccountProps = {
    username: string;
    keycloak: Partial<Keycloak.KeycloakInstance>;
    setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
    hasUnreadMessages: boolean; // Prop für ungelesene Nachrichten
    setHasUnreadMessages: Dispatch<SetStateAction<boolean>>;
};

export const Account = ({ username, keycloak, setMenuIsOpen, hasUnreadMessages, setHasUnreadMessages }: AccountProps): ReactElement => {
    const dispatch = useDispatch();
    const hasOpenBestellung = useSelector(selectHasOpenBestellung);

    const closeMenu = (): void => {
        setMenuIsOpen(false);
    };

    const logout = (logoutUrl: string): void => {
        dispatch(clearBestellung());
        FlutterBridge.sendToken(null);
        const allowCookies = localStorage.getItem(ALLOW_COOKIES_KEY);
        localStorage.clear();
        if (allowCookies !== null) {
            localStorage.setItem(ALLOW_COOKIES_KEY, allowCookies);
        }

        window.location.href = logoutUrl;
    };

    const confirmLogoutDialog = (logoutUrl: string): void => {
        showConfirmationDialog({
            message: (
                <>
                    Sie haben noch eine Bestellung, die nicht abgeschlossen ist. Wenn Sie fortfahren, werden Ihre Bestellpositionen
                    gelöscht.
                </>
            ),
            okButtonText: 'Bestellpositionen löschen',
            onOkCallback: () => {
                logout(logoutUrl);
            },
        });
    };

    const shouldLogout = () => {
        setMenuIsOpen(false);
        const logoutUrl = (keycloak.createLogoutUrl && keycloak?.createLogoutUrl()) || '';
        if (hasOpenBestellung) {
            confirmLogoutDialog(logoutUrl);
        } else {
            logout(logoutUrl);
        }
    };

    const { isApp } = Config.getConfig();

    const navigateToNachrichten = () => {
        window.location.href = routes.nachrichten;

        window.addEventListener('load', () => {
            setHasUnreadMessages(false);
        });
        setTimeout(() => {
            setMenuIsOpen(false);
            markAllMessagesAsRead();
        }, 600);
        markAllMessagesAsRead();
    };

    return (
        <div>
            <div className={styles._accountContainer}>
                <Link onClick={closeMenu} to={{ pathname: '/accountSettings' }} className={styles._accountIconLink}>
                    <img src={accountIcon} className={styles._accountIcon} alt="Benutzer" />
                </Link>

                <div>
                    <label className={styles._menuLabel}>{username}</label>
                    <button onClick={shouldLogout} className={styles._account} id="login-logout-button">
                        Abmelden
                    </button>
                </div>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    {isApp() && (
                        <img
                            src={notificationIcon}
                            alt="Benachrichtigungen"
                            style={{ width: '30px', height: '32px', marginLeft: '60px', marginTop: '-45px' }}
                            onClick={() => navigateToNachrichten()}
                        />
                    )}
                    {isApp() && hasUnreadMessages && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                width: '10px',
                                height: '10px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                border: '2px solid white',
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
